import { useWinners } from "./useChallengeWinnersData";
import { useMemo } from "react";
import { Challenge } from "../types/challengeDataResponse";
import { leaderboardUsers } from "../types/leaderboardUsersResponse";
import { WinnerResponse } from "../types/winnersResponse";
import { useRealtimeDBLeaderboard } from "./useRealtimeLeaderboard";
import { useCurrentEventChallenges } from "./useCurrentEventChallenges";

export type ClientProps = {
  leaderboard: {
    loading: boolean;
    data: leaderboardUsers[] | null;
    function: () => void;
    length: number;
  };

  winners: {
    loading: boolean;
    data: WinnerResponse[] | null;
    function: () => void;
  };

  challenge: {
    loading: boolean;
    data: Challenge | null;
    function: () => void;
  };

  nextChallenges: {
    loading: boolean;
    data: Challenge[] | null;
    function: () => void;
  };
  hasActiveChallenge: boolean;
};

export const useClientData = (client: string) => {
  const {
    fetchUpcomingData,
    loadingNextChallenge,
    nextChallenges,
    fetchOngoingData,
    loadingOngoingChallenge,
    ongoingChallenge,
  } = useCurrentEventChallenges();

  const { fetchNewWinners, winnersData, loadingWinners } = useWinners(client);

  const { loadingRealtimeLeaderboard, leaderboardData, fetchAllLeaderboard } =
    useRealtimeDBLeaderboard({
      slug: ongoingChallenge?.slug,
    });

  const clientData = useMemo<ClientProps>(
    () => ({
      leaderboard: {
        loading: loadingRealtimeLeaderboard,
        data: leaderboardData,
        function: fetchAllLeaderboard,
        length: leaderboardData ? leaderboardData.length : 0,
      },
      winners: {
        loading: loadingWinners,
        data: winnersData,
        function: fetchNewWinners,
      },
      challenge: {
        loading: loadingOngoingChallenge,
        data: ongoingChallenge,
        function: fetchOngoingData,
      },
      nextChallenges: {
        loading: loadingNextChallenge,
        data: nextChallenges,
        function: fetchUpcomingData,
      },
      hasActiveChallenge: ongoingChallenge?.slug,
    }),
    [
      loadingRealtimeLeaderboard,
      leaderboardData,
      loadingWinners,
      winnersData,
      loadingOngoingChallenge,
      ongoingChallenge,
      fetchOngoingData,
      loadingNextChallenge,
      nextChallenges,
      fetchUpcomingData,
    ]
  );

  return { clientData };
};
